<template>
  <div>
    <section class="section is-main-section">
    <b-message title="Não autorizado!" class="warning-message" type="is-danger" aria-close-label="Close message" :closable="false">
      Usuário não autorizado a acessar este recurso!
    </b-message>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'NotAutorized'
}
</script>
